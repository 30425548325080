@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;1,100;1,200;1,300;1,400;1,500&display=swap');
/* Styles for toolbar */
* {
  font-family: 'Poppins', sans-serif;
}

/* SVG default styles */
.svg,
.groupedButtons:hover + .svg {
  fill: #000000; /* Default fill color (black) */
  transition: fill 0.3s ease;
  cursor: pointer;
}

/* Text default styles */
.groupedButtons:hover + .svg,
.svg:hover {
  fill: #ffffff; /* Hover fill color (white) */
}

.inputbutton {
  background: #ffffff;
  font-size: 12px;
  color: #000;
  padding: 10px 15px;
  border: 1px solid #575757;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.inputbutton:hover {
  background-color: #a1a8ae;
  color: #000000;
}

.head {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  padding: 5px 20px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header label {
  cursor: pointer;
}

.header button {
  background: #ffffff;
  color: #000000;
  font-size: 12px;
  height: 70px;
  width: 80px;
  padding: 8px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: none;
}

.header button:hover {
  background-color: #a1a8ae;
  color: #000000;
}

/* Styles for mainboard */
.mainboard {
  display: flex;
  flex-direction: column;
  // padding: 20px;
}

.mainboard canvas {
  border: 1px solid #dddddd;
  // border-radius: 5px;
  margin-bottom: 20px;
}

/* Styles for buttons in designsButton */
.groupedButtons {
  display: flex;
  border: none;
  align-items: center;
}
.bimbotsLogo {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  margin-bottom: 2%;
  bottom: 0;
  right: 2vw;
  align-items: center;
}
.designsButton {
  display: flex;
  fill: transparent;
  align-items: center;
}

.inputContainer {
  margin-left: 10px;
}

.uploadLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 8px 12px;
  background-color: #575757;
  // border: 1px solid #575757;
  color: #333;
}

.uploadLabel input {
  display: none; /* Hide the default file input */
}

/* Styles for upload PDF input */
.inputbutton input[type='file'] {
  display: none;
}

.inputbutton label {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.inputbutton span {
  padding: 8px 15px;
  background: #575757;
  color: #343a40;
  // border: 1px solid #575757;
}

.inputbutton input[type='file'] {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

/* Hover effect for upload PDF */
.inputbutton:hover span {
  background-color: #343a40;
}

/* Focus effect for upload PDF */
.inputbutton input[type='file']:focus + span {
  outline: none;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

.uploadInputContainer {
  position: absolute; /* Adjust the top position as needed */
  right: 70%; /* Adjust the right position as needed */
}

.lockPdfButton {
  border: 1px solid #575757; /* Set the border color */
  width: 45px;
  height: 44px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lockPdfContainer {
  position: absolute;
  border: 1px solid #575757; /* Set the border color */
  width: 45px;
  height: 44px; /* Set the height to match the upload button */
  right: 67%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lockPdfContainer button {
  background: #ffffff;
  color: #000000;
  font-size: 12px;
  height: 100%; /* Set the height to fill the container */
  width: 100%;
  padding: 8px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: none;
}

.lockPdfContainer button:hover {
  background-color: #a1a8ae;
  color: #000000;
}

.ductButtonContainer {
  display: flex;
  align-items: center;
}
.ductButtonContainer button {
  width: 60px;
  height: 60px;
  font-size: 10px;
}
.scaleButtonContainer {
  position: absolute; /* Adjust the top position as needed */
  right: 34.8%; /* Adjust the right position as needed */
}

/* Styles for Scale button */
.scaleButton {
  display: flex;
  align-items: center;
}

.scaleButton select {
  margin-right: 10px;
  padding: 8px;
  border-radius: 5px;
  // border: 1px solid #ccc;
  background-color: #fff;
}

.scaleButton button {
  background: #575757;
  color: #fff;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.scaleButton button:hover {
  background-color: #333;
}

/* Additional styles */

.buttonDisabled {
  opacity: 0.5;
  cursor: not-allowed;
}

// canvas css
.canvasContainer {
  position: relative;
}

.responsiveBox {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 80%;
  margin: 0 auto;
}
.leftBox {
  position: absolute;
  top: 30%;
  left: 0%;
  transform: translateY(-50%);
  border: 1px solid #c4c3c3;
  border-radius: 3px;
  z-index: 1;
}
.rightBox {
  position: absolute;
  top: 30%;
  right: 0;
  transform: translateY(-50%);
  border: 1px solid #c4c3c3;
  border-radius: 3px;
  z-index: 1;
}
.buttonStyle {
  width: 100%;
  background-color: #fff;
  color: #000000;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
}

.buttonStyle:hover {
  background-color: #4e5fbf;
  color: black;
}

#canvas {
  width: 10px;
  height: 400px;
  background-color: #f0f0f0;
  z-index: 0;
  position: relative;
}

/* PdfList.scss */

.PdfListContainer {
  position: absolute;
  top: 80%;
  left: 50px;
  transform: translateY(-50%);
  background-color: #f0f0f0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  max-height: 80%;
  overflow-y: auto;
}

.PdfListContainer h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

.PdfListContainer ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.PdfListContainer li {
  margin-bottom: 10px;
}

.PdfListContainer strong {
  font-weight: bold;
}

.PdfListContainer ul ul {
  margin-top: 5px;
  margin-left: 15px;
}

.PdfListContainer ul ul li {
  margin-bottom: 5px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  animation: fadeIn 0.3s ease-in-out;
}

.tables {
  border-collapse: collapse;
  width: 100%;

  th {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    background-color: #f2f2f2;
  }

  td {
    /* Example styles for table body cells */
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 16px;

    input[type='text'] {
      /* Example styles for input fields */
      width: 100%;
      padding: 6px;
      box-sizing: border-box;
    }

    .deleteButton {
      background-color: transparent;
      font-size: 20px;
      color: black;
      cursor: pointer;
      border: none;
      padding: 0;
    }

    // .deleteButton:hover {
    //   color: white; // Change color on hover
    // }

    .iconButton {
      // border: 1px solid #ccc;
      padding: 10px;
      border-radius: 5%;
    }

    // .iconButton:hover {
    //   background-color: #f0f0f0;
    // }

    button {
      /* Example styles for buttons */
      padding: 6px 10px;
      background-color: #ff6347;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }
  }

  tr:nth-child(even) {
    /* Example styles for alternating row colors */
    background-color: #f9f9f9;
  }
}

.actionColumn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.selectedButton {
  background-color: #4e5fbf;
  animation: highlightBackground 0.3s ease;
}

@keyframes highlightBackground {
  from {
    background-color: transparent;
  }
  to {
    background-color: #4e5fbf;
  }
}
