.sh-spinner {
  width: 100%;
  height: 100%;
}

.sh-spinner > div {
  display: flex;
  position: fixed;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 99999999;
  background-color: rgba(0, 0, 0, 0.8);
}

@media screen and (orientation: portrait) {
  .sh-spinner {
    display: none;
  }
  .sh-spinner > div {
    display: none;
  }
}
