iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

* {
  margin: 0%;
}
html,
body {
  margin: 0;
  overflow-y: hidden;
}

/* customScrollbar.css */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #8091F2;
  border-radius: 6px;
  border: 3px solid #f1f1f1;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}
